<template>
  <div class="userinfo-wallet">
    <div class="top-info">
      <div class="title">{{ $t('zhang-hu-zong-lan') }}</div>
      <div class="flex-center-between money-info">
        <div class="flex-center">
          <div class="icon flex-center">
            <i class="iconfont icon-qianbao"></i>
          </div>
          <div>
            <div class="flex-center-start money">
              {{ userMoney.toFixed(2) }}
              <img
                :src="showMoney ? eyeUrl2 : eyeUrl"
                alt=""
                @click="showMoney = !showMoney"
              />
            </div>
            <div class="label">{{ $t('zhang-hu-yueusd') }}</div>
          </div>
        </div>
        <div class="btn-box">
          <el-button class="btn1" @click="toPath('userInfoRecharge')">{{
            $t('chong-zhi')
          }}</el-button>
          <el-button class="btn2" @click="toPath('userInfoWithdraw')">{{
            $t('ti-xian')
          }}</el-button>
        </div>
      </div>
    </div>

    <div class="bottom-table">
      <div class="title">{{ $t('qian-bao-li-shi') }}</div>
      <div class="btn-box">
        <el-button
          :class="tabIndex == 1 ? 'btn1' : 'btn2'"
          @click="changeTab(1)"
          >{{ $t('chong-zhi') }}</el-button
        >
        <el-button
          :class="tabIndex == 2 ? 'btn1' : 'btn2'"
          @click="changeTab(2)"
          >{{ $t('ti-xian') }}</el-button
        >
      </div>
      <div class="table-box">
        <el-table :data="tableData" border>
          <el-table-column
            :label="$t('ri-qi')"
            prop="CreatedAt"
          ></el-table-column>
          <el-table-column
            :label="$t('ding-dan-hao-0')"
            prop="date"
          ></el-table-column>
          <el-table-column
            :label="tabIndex === 1 ? $t('chong-zhi-shu-liang') : $t('ti-xian-jin-e')"
            prop="Amount"
          ></el-table-column>
          <el-table-column
            :label="$t('bi-zhong-xie-yi')"
            prop="date"
          ></el-table-column>
          <el-table-column :label="$t('ding-dan-zhuang-tai')" prop="Status">
            <template slot-scope="scope">
              <div>{{ getStatusName(scope.row.Status) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('zhi-fu-ping-zheng')"
            prop="PaymentVoucher"
          >
            <template slot-scope="scope">
              <img
                :src="scope.row.PaymentVoucher"
                v-if="scope.row.PaymentVoucher"
                alt=""
              /> </template
          ></el-table-column>
          <el-table-column
            :label="$t('shi-ji-dao-zhang')"
            prop="Amount"
          ></el-table-column>
          <el-table-column
            :label="tabIndex === 1 ? $t('chong-zhi-di-zhi') : $t('ti-xian-di-zhi')"
            prop="date"
          ></el-table-column>
        </el-table>
      </div>

      <div class="flex-center user-pager mt-10">
        <el-pagination
          background
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout=" prev, pager,  next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getWalletInfo, rechargeLog, withdrawLog } from '@/api/user'
export default {
  data() {
    return {
      userMoney: 0,
      showMoney: true,
      tabIndex: 1,
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      tableData: [],
      page: {
        size: 10,
        current: 1,
        total: 1
      }
    }
  },
  mounted() {
    this.init()
    this.initTable()
  },
  methods: {
    init() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    initTable() {
      let param = {
        current: this.page.current,
        pageSize: this.page.size
      }
      if (this.tabIndex == 1) {
        rechargeLog(param).then((res) => {
          this.tableData = res.data.Items
          this.page.total = res.data.Pagination.totalRecords
        })
      } else {
        withdrawLog(param).then((res) => {
          this.tableData = res.data.Items
          this.page.total = res.data.Pagination.totalRecords
        })
      }
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    changeTab(i) {
      this.tabIndex = i
      this.initTable()
    },
    currentChange(page) {
      this.page.current = page
      this.initTable()
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-shen-he')
          break
        case '1':
          name = this.$t('yi-tong-guo')
          break
        case '2':
          name = this.$t('yi-ju-jue')
          break
      }
      return name
    }
  }
}
</script>